<template>
    <div v-if="columnData">
        {{columnData.region}}
        <br>
        {{columnData.country}}
    </div>
</template>

<script>
export default {
    props: {
        columnData: {
            'region': String,
            'country': String,
        },
    }
}
</script>

<style scoped>

</style>